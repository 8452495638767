import dashboard from './dashboard'
import { utilities, logout } from './utilities'
import csvoperator from './csvoperator'
import csvagency from './csvagency'

// ==============================|| MENU ITEMS ||============================== //

// const menuItems = {
//     // items: [dashboard, utilities, csvoperator, csvagency]
//     items: [
//         dashboard,
//         ...utilities, // Spread the utilities array to include the Logout item as a separate entry
//         csvoperator,
//         csvagency
//     ]
// };

const menuItems = {
    items: [dashboard, utilities, logout, csvoperator, csvagency]
}

export default menuItems
