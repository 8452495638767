import React from 'react'
import { Typography, Button } from '@mui/material'
import NavGroup from './NavGroup'
import menuItem from 'menu-items'
import { useNavigate } from 'react-router'

const MenuList = ({ userRole }) => {
    // Filter menu items based on user role
    const navigate = useNavigate()

    const filteredItems = menuItem.items.filter(item => {
        if (item.allowedRoles && item.allowedRoles.includes(userRole)) {
            return true
        }
        return item.id === 'logout' // Ensure Logout is always included
    })

    const navItems = filteredItems.map((item, index) => {
        switch (item.type) {
            case 'group':
                return <NavGroup key={index} item={item} />
        }
    })

    // Add Logout Button separately
    const logoutItem = menuItem.items.find(item => item.id === 'logout')
    const handlelogin = () => {
        localStorage.clear()
        navigate('/')
    }

    return (
        <>
            {navItems}
            {logoutItem && (
                <Button variant="contained" color="secondary" onClick={handlelogin} style={{ margin: '10px 5px', width: '100%' }}>
                    {logoutItem.title}
                </Button>
            )}
        </>
    )
}

export default MenuList
