// import { LocalizationProvider } from '@mui/lab';
// import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { CssBaseline, StyledEngineProvider } from '@mui/material'
import { ThemeProvider } from '@mui/material/styles'
// project imports
import NavigationScroll from 'layout/NavigationScroll'
import { useSelector } from 'react-redux'
// routing
import Routes from 'routes'

// defaultTheme
import themes from 'themes'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
// ==============================|| APP ||============================== //

const App = () => {
    const customization = useSelector(state => state.customization)

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <StyledEngineProvider injectFirst>
                <ThemeProvider theme={themes(customization)}>
                    <CssBaseline />
                    <NavigationScroll>
                        <Routes />
                    </NavigationScroll>
                </ThemeProvider>
            </StyledEngineProvider>
        </LocalizationProvider>
    )
}

export default App
