// project imports
import { Navigate } from 'react-router-dom'
import MainLayout from 'layout/MainLayout'
import { lazy } from 'react'
import Loadable from 'ui-component/Loadable'

// dashboard routing
const DashboardDefault = Loadable(lazy(() => import('views/dashboard/Default')))

// utilities routing

// sample page routing
const AddState = Loadable(lazy(() => import('views/master/States/index')))
const AddCity = Loadable(lazy(() => import('views/master/city/index')))
const Game = Loadable(lazy(() => import('views/master/Game/index')))
const Operator = Loadable(lazy(() => import('views/master/operator/index')))
const Operat = Loadable(lazy(() => import('views/operator-trip-manager/operat/index')))
const OperatPending = Loadable(lazy(() => import('views/operator-trip-manager/operator-pending/index')))
const OperatComplete = Loadable(lazy(() => import('views/operator-trip-manager/operator-complete/index')))
const OperatFreez = Loadable(lazy(() => import('views/operator-trip-manager/operator-freez/index')))
const OperatInreview = Loadable(lazy(() => import('views/operator-trip-manager/operator-inreview/index')))
const TransprotTrip = Loadable(lazy(() => import('views/transport-trip-master/transport/index')))
const TransportComplete = Loadable(lazy(() => import('views/transport-trip-master/transport-complete/index')))
const TranportFreez = Loadable(lazy(() => import('views/transport-trip-master/transport-freez/index')))
const OperatorTripManager = Loadable(lazy(() => import('views/operator-trip-manager/trip-master/index')))
const AddOperatorTripManager = Loadable(lazy(() => import('views/operator-trip-manager/trip-master/AddOperator')))
const TransportInreview = Loadable(lazy(() => import('views/transport-trip-master/transport-inreview/index')))
const TransportPending = Loadable(lazy(() => import('views/transport-trip-master/transport-pending/index')))
const Agency = Loadable(lazy(() => import('views/master/agency/index')))
const Transport = Loadable(lazy(() => import('views/master/transport/index')))
const ScheduleMaster = Loadable(lazy(() => import('views/master/schdulemaster/index')))
const AuthLogin3 = Loadable(lazy(() => import('views/pages/authentication/authentication3/Login3')))
// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
    path: '/',
    exact: true,
    element: <MainLayout />,
    children: [
        {
            path: '/',
            element: <Navigate to="/login" />
        },
        {
            path: '/dashboard/default',
            element: <DashboardDefault />
        },
        {
            path: '/master/state',
            element: <AddState />
        },
        {
            path: '/master/city',
            element: <AddCity />
        },
        {
            path: '/master/depo',
            element: <Game />
        },
        {
            path: '/depo/operator',
            element: <Operator />
        },
        {
            path: '/depo/schedulemaster',
            element: <ScheduleMaster />
        },
        {
            path: '/depo/agency',
            element: <Agency />
        },
        {
            path: '/depo/transport',
            element: <Transport />
        },
        {
            path: '/operator/operator-trip',
            element: <Operat />
        },
        {
            path: '/operator/get-by-status/pending',
            element: <OperatPending />
        },
        {
            path: '/operator/get-by-status/freezed',
            element: <OperatFreez />
        },
        {
            path: '/operator/get-by-status/complete',
            element: <OperatComplete />
        },
        {
            path: '/operator/get-by-status/in-review',
            element: <OperatInreview />
        },
        {
            path: '/transport/transport-trip',
            element: <TransprotTrip />
        },
        {
            path: '/transport/get-by-status/complete',
            element: <TransportComplete />
        },
        {
            path: '/transport/get-by-status/freezed',
            element: <TranportFreez />
        },
        {
            path: '/transport/get-by-status/in-review',
            element: <TransportInreview />
        },
        {
            path: '/transport/get-by-status/pending',
            element: <TransportPending />
        },
        {
            path: '/operator-trip-manager/view',
            element: <OperatorTripManager />
        },
        {
            path: '/add-operator-trip-manager',
            element: <AddOperatorTripManager />
        }
    ]
}

export default MainRoutes
