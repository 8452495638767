import PropTypes from 'prop-types'

// material-ui
import { useTheme } from '@mui/material/styles'
import { Avatar, Box, ButtonBase } from '@mui/material'
import { useState, useEffect } from 'react'
// project imports
import LogoSection from '../LogoSection'
import SearchSection from './SearchSection'
import ProfileSection from './ProfileSection'
import NotificationSection from './NotificationSection'

// assets
import { IconMenu2 } from '@tabler/icons'

// ==============================|| MAIN NAVBAR / HEADER ||============================== //

const Header = ({ handleLeftDrawerToggle }) => {
    const [currentDateTime, setCurrentDateTime] = useState('')

    useEffect(() => {
        // Function to update the current date and time every second
        const updateDateTime = () => {
            const now = new Date()

            // Format time
            const hours = now.getHours().toString().padStart(2, '0')
            const minutes = now.getMinutes().toString().padStart(2, '0')
            const seconds = now.getSeconds().toString().padStart(2, '0')
            const formattedTime = `${hours}:${minutes}:${seconds}`

            // Format date
            const year = now.getFullYear().toString()
            const month = (now.getMonth() + 1).toString().padStart(2, '0')
            const day = now.getDate().toString().padStart(2, '0')
            const formattedDate = `${year}-${month}-${day}`

            // Combine date and time
            const formattedDateTime = `${formattedDate} ${formattedTime}`

            setCurrentDateTime(formattedDateTime)
        }

        // Call the updateDateTime function every second
        const intervalId = setInterval(updateDateTime, 1000)

        // Clean up the interval when the component unmounts
        return () => clearInterval(intervalId)
    }, [])

    const theme = useTheme()

    return (
        <>
            {/* logo & toggler button */}
            <Box
                sx={{
                    width: 228,
                    display: 'flex',
                    [theme.breakpoints.down('md')]: {
                        width: 'auto'
                    }
                }}>
                <Box component="span" sx={{ display: { xs: 'none', md: 'block' }, flexGrow: 1 }}>
                    <LogoSection />
                </Box>
                <ButtonBase sx={{ borderRadius: '12px', overflow: 'hidden' }}>
                    <Avatar
                        variant="rounded"
                        sx={{
                            ...theme.typography.commonAvatar,
                            ...theme.typography.mediumAvatar,
                            transition: 'all .2s ease-in-out',
                            background: theme.palette.secondary.light,
                            color: theme.palette.secondary.dark,
                            '&:hover': {
                                background: theme.palette.secondary.dark,
                                color: theme.palette.secondary.light
                            }
                        }}
                        onClick={handleLeftDrawerToggle}
                        color="inherit">
                        <IconMenu2 stroke={1.5} size="1.3rem" />
                    </Avatar>
                </ButtonBase>
            </Box>

            {/* header search */}
            {/* <SearchSection /> */}
            <Box sx={{ flexGrow: 1 }} />
            <Box sx={{ flexGrow: 1 }} />
            {/* <div>
                <span>{currentDateTime}</span>
            </div> */}
            {/* notification & profile */}
            {/* <NotificationSection />
            <ProfileSection /> */}
        </>
    )
}

Header.propTypes = {
    handleLeftDrawerToggle: PropTypes.func
}

export default Header
