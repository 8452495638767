// material-ui
import { Typography } from '@mui/material'
import { useTheme } from '@mui/material/styles'

/**
 * if you want to use image instead of <svg> uncomment following.
 *
 * import logoDark from 'assets/images/logo-dark.svg';
 * import logo from 'assets/images/logo.svg';
 *
 */

// ==============================|| LOGO SVG ||============================== //

const Logo = () => {
    const theme = useTheme()

    return (
        <Typography variant="h2" color={theme.palette.primary.main}>
            Yeppo
        </Typography>
    )
}

export default Logo
