const config = {
    // basename: only at build time to set, and Don't add '/' at end off BASENAME for breadcrumbs, also Don't put only '/' use blank('') instead,
    // like '/berry-material-react/react/default'
    basename: '/',
    defaultPath: '/login',
    fontFamily: `'Roboto', sans-serif`,
    borderRadius: 12,
    // baseUrl: `https://server.yeppoindia.com`
    // baseUrl: `http://localhost:8000`
    // baseUrl : `https://billing.yeppoindia.com`
    baseUrl : `https://server.yeppoindia.com`

}
// apiBaseUrl: `http://3.111.156.159/api`

export default config
