// assets
import { IconPalette, IconShadow, IconTypography, IconWindmill, IconLogout } from '@tabler/icons'

// constant
const icons = {
    IconTypography,
    IconPalette,
    IconShadow,
    IconWindmill,
    IconLogout // Add the logout icon
}

// ==============================|| UTILITIES MENU ITEMS ||============================== //

// const utilities = [
//     {
//         id: 'utilities',
//         title: '',
//         type: 'group',
//         allowedRoles: ['depo'],
//         children: [
//             {
//                 id: 'master',
//                 title: 'Depo',
//                 type: 'collapse',
//                 icon: icons.IconWindmill,
//                 children: [
//                     {
//                         id: 'Operator',
//                         title: 'Operator',
//                         type: 'item',
//                         url: '/depo/operator',
//                         breadcrumbs: false
//                     },
//                     {
//                         id: 'TransportAgency',
//                         title: 'Transport Agency',
//                         type: 'item',
//                         url: '/depo/transport',
//                         breadcrumbs: false
//                     },
//                     {
//                         id: 'Schedule',
//                         title: 'Schedule Master',
//                         type: 'item',
//                         url: '/depo/schedulemaster',
//                         breadcrumbs: false
//                     },
//                     {
//                         id: 'state',
//                         title: 'State',
//                         type: 'item',
//                         url: '/master/state',
//                         breadcrumbs: false
//                     }
//                 ]
//             }
//         ]
//     },
//     {
//         id: 'logout',
//         title: 'Logout',
//         type: 'item',
//         icon: icons.IconLogout, // Use logout icon
//         url: '/logout', // URL for the logout functionality
//         breadcrumbs: false
//     }
// ];
const utilities = {
    id: 'utilities',
    title: 'Utilities',
    type: 'group',
    allowedRoles: ['depo'],
    children: [
        {
            id: 'master',
            title: 'Depo',
            type: 'collapse',
            icon: icons.IconWindmill,
            children: [
                {
                    id: 'Operator',
                    title: 'Operator',
                    type: 'item',
                    url: '/depo/operator',
                    breadcrumbs: false
                },
                {
                    id: 'TransportAgency',
                    title: 'Transport Agency',
                    type: 'item',
                    url: '/depo/transport',
                    breadcrumbs: false
                },
                {
                    id: 'Schedule',
                    title: 'Schedule Master',
                    type: 'item',
                    url: '/depo/schedulemaster',
                    breadcrumbs: false
                }
                // {
                //     id: 'state',
                //     title: 'State',
                //     type: 'item',
                //     url: '/master/state',
                //     breadcrumbs: false
                // }
            ]
        }
    ]
}

// Standalone Logout Button
const logout = {
    id: 'logout',
    title: 'Logout',
    type: 'item',
    icon: icons.IconLogout,
    url: '/logout',
    breadcrumbs: false
}

export { utilities, logout }

// export default utilities;
